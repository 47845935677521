import "instantsearch.css/themes/satellite.css";
import "./App.css";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Hits,
  Pagination,
  RefinementList,
  RangeInput,
} from "react-instantsearch-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Icon,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useGetOrderMediaConfig } from "../views/PlaceOrderTest/queryHooks";

const FacetedPartsearch = ({ onClose, isOpen }: any) => {
  const { data: mobileConfigs } = useGetOrderMediaConfig();

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      nodes: [
        {
          host: mobileConfigs?.typesense_host,
          port: 443,
          protocol: "https",
        },
      ],
      apiKey: mobileConfigs?.typesense_api_key,
    },
    additionalSearchParameters: {
      query_by: "partName,partNumber",
    },
  });
  const Hit = ({ hit }: any) => {
    return (
      <div className="hit">
        <div className="hit-content">
          <div>{hit.partName}</div>
          <div>{hit.partNumber}</div>
          <div>{hit.brand}</div>
          <div>₹{hit.mrp}</div>
        </div>
      </div>
    );
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={"flex"} justifyContent={"space-between"}>
          Part Search
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div>
            <InstantSearch
              indexName="partmasters"
              searchClient={typesenseInstantsearchAdapter.searchClient}
            >
              <Configure ruleContexts={[]} />
              <div className="container">
                <div className="facets">
                  <div className="facet">
                    <h2>Brands</h2>
                    <RefinementList
                      attribute="brand"
                      searchable={true}
                      searchablePlaceholder="Search brands"
                      showMore={true}
                    ></RefinementList>
                  </div>

                  <div className="facet">
                    <h2>Origin</h2>
                    <RefinementList attribute="origin"></RefinementList>
                  </div>
                  <div className="facet">
                    <h2>Category</h2>
                    <RefinementList attribute="category"></RefinementList>
                  </div>
                  <div className="facet">
                    <h2>MRP</h2>
                    <RangeInput attribute="mrp"></RangeInput>
                  </div>
                </div>

                <div className="search-results">
                  {" "}
                  <SearchBox autoFocus />
                  <Configure hitsPerPage={20} />
                  <Hits hitComponent={Hit} />
                  <Pagination className="Pagination" />
                </div>
              </div>
            </InstantSearch>
          </div>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FacetedPartsearch;
