import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Text,
} from "@chakra-ui/react";

interface GenericAlert {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  leftBtnText: any;
  submitBtnText: string;
  onSubmitBtn: () => void;
  alertBodyText: any;
  alertGenericHeaderText: string;
  color: string;
  icon: any;
  isLeftButtonDisabled?: boolean;
}

function GaraazGenericAlert(props: GenericAlert) {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      motionPreset="scale"
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      size={"xl"}
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        borderRadius={10}
        borderTop={`7px solid ${props.color}`}
      >
        <AlertDialogBody
          color="black"
          alignItems={"center"}
          marginTop={0}
          display="flex"
        >
          <Box
            paddingLeft={2}
            marginRight={"0.2em"}
            marginTop={0}
            color={props.color}
            alignSelf={"flex-start"}
          >
            {props.icon}
          </Box>

          <Box ml={5}>
            <Box>
              <Text fontWeight="bold" fontSize="xl">
                {props.alertGenericHeaderText}
              </Text>
            </Box>
            <Box>
              <div>
                <Text
                  fontSize={"md"}
                  lineHeight={"normal"}
                  fontWeight={"medium"}
                >
                  {props.alertBodyText}
                </Text>
              </div>
            </Box>
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter borderTop="1px solid #dfdfdf" p={3}>
          <Button
            size={"sm"}
            ref={cancelRef}
            onClick={props.onClose}
            style={{ backgroundColor: "transparent" }}
            border="1px solid #8fb7f4"
            color={"#78b3f0"}
            _hover={{ backgroundColor: "transparent ! important" }}
            isDisabled={props.isLeftButtonDisabled}
          >
            {props.leftBtnText}
          </Button>
          <Button
            size={"sm"}
            backgroundColor={props.color}
            _hover={{
              backgroundColor: `${props.color}`,
            }}
            onClick={props.onSubmitBtn}
            ml={3}
            color={"white"}
          >
            {props.submitBtnText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default GaraazGenericAlert;
