import React, { useState } from "react";
import { Box, Input, Button, Text } from "@chakra-ui/react";
import VerifiedIcon from "@mui/icons-material/Verified";
import services from "../services";
import { GaraazApproveReject, GaraazTextComponent } from "@svan-garaaz/reactsharedlib";

interface Props {
  label: string;
  value: string;
  inputValue: string,
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  orderNo: number;
  customerRemarks: string;
  orderMedia: string[];
  isVerified: boolean;
  setIsVerified: React.Dispatch<React.SetStateAction<boolean>>;
}

const GaraazVinLpnEditor = ({
  label,
  value,
  inputValue,
  setInputValue,
  orderNo,
  customerRemarks,
  orderMedia,
  isVerified,
  setIsVerified
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleCorrectUpdate = async () => {
    await services.updateOrderPhantomFeedback({
      orderNo,
      [label]: value,
      [`is${
        label === "vinNumber" ? "VinNumber" : "LicensePlateNumber"
      }Correct`]: true,
      [`correct${label === "vinNumber" ? "VinNumber" : "LicensePlateNumber"}`]:
        value,
      customerRemarks,
      orderMedia,
    });
    setIsVerified(true);
  };

  const handleUpdate = async () => {
    await services.updateOrderPhantomFeedback({
      orderNo,
      [label]: value,
      [`is${
        label === "vinNumber" ? "VinNumber" : "LicensePlateNumber"
      }Correct`]: value?.trim() === inputValue?.trim(),
      [`correct${label === "vinNumber" ? "VinNumber" : "LicensePlateNumber"}`]:
        inputValue?.trim(),
      customerRemarks,
      orderMedia,
    });
    setIsEditing(false);
    setIsVerified(true);
  };

  const isLicensePlateNumberValid = (value: string) => {
    return /^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{0,3}[0-9]{4}$/.test(value);
  };

  const isVinNumberValid = (value: string) => {
    return (
      value?.trim().length >= 17 &&
      !value.includes("I") &&
      !value.includes("O") &&
      !value.includes("Q")
    );
  };

  return (
    <Box display="flex" alignItems="center" mb={1}>
      {isEditing ? (
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Text fontWeight="bold" color={"#0096e0"}>
            {label === "vinNumber" ? "VIN Number:" : "License Plate Number:"}
          </Text>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`Enter ${label}`}
            size="xs"
            width={label === "vinNumber" ? "44" : "32"}
          />
          <Button
            onClick={() => {
              setIsEditing(false);
              setInputValue(value);
            }}
            size={"xs"}
            fontSize="xs"
            py={2}
            height={"24px"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            size={"xs"}
            fontSize="xs"
            py={2}
            height={"24px"}
            disabled={
              (label === "vinNumber"
                ? !isVinNumberValid(inputValue)
                : !isLicensePlateNumberValid(inputValue)) ||
              value?.trim() === inputValue?.trim()
            }
            colorScheme="blue"
            style={{ color: "white" }}
          >
            Confirm
          </Button>
        </Box>
      ) : (
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <GaraazTextComponent
            header={
              label === "vinNumber" ? "VIN Number" : "License Plate Number"
            }
            content={inputValue || "Not Provided"}
            maxNoOfLines={1}
            isHorizontal={true}
            style={{ marginBottom: 0 }}
            copyable={!!inputValue}
          />
          {inputValue && (
            <>
              {isVerified ? (
                <Box
                  color={"#00bc6b"}
                  display={"flex"}
                  gap={2}
                  alignItems={"center"}
                  fontSize={"md"}
                >
                  <VerifiedIcon fontSize="inherit" />
                </Box>
              ) : (
                <GaraazApproveReject
                  onConfirm={handleCorrectUpdate}
                  onCancel={() => {
                    setIsEditing(true);
                    setInputValue(value);
                  }}
                  isConfirmDisabled={
                    label === "vinNumber"
                      ? !isVinNumberValid(inputValue)
                      : !isLicensePlateNumberValid(inputValue)
                  }
                />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default GaraazVinLpnEditor;
