import { Stack, Tooltip, ThemeProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { getGaraazStateColor, globalOrderStatus } from "../enums";
import services from "../services";
import { OrderHandlers } from "../index";
import { useGetAllSuppliers, useGetBrandsInfo } from "../queryHooks";
import { removeUnderScoreWithUpperCase } from "../../../utils/removeUnderscore";
import GaraazOrderComponentFooter from "./GaraazOrderComponentFooter";
import GaraazOrderComponentHeader from "./GaraazOrderComponentHeader";
import GaraazOrderComponentBody from "./GaraazOrderComponentBody";
import GaraazOrderComponentFooterReturn from "./GaraazOrderComponentFooterReturn";
import GaraazOrderComponentFooterSubscription from "./GaraazOrderComponentFooterSubscription";

const theme = extendTheme({
  components: {
    Text: {
      baseStyle: {
        fontSize: "small",
      },
    },
  },
});

export type OrderRemark = {
  userName: string;
  remarks: string;
};

export type AllocatedTo = {
  supplierId: string;
  branchId: string;
  orderRemarks: string;
  orderStatus: string;
  invoiceInfo?: any;
  isActive: boolean;
  customerType?: string;
  discount?: number;
  parentSupplierId?: string;
};

export const finalOrcaStates = [
  globalOrderStatus.CANCELLED,
  globalOrderStatus.CUSTOMER_RETURN_REJECTED,
  globalOrderStatus.SUPPLIER_RETURNED,
  globalOrderStatus.SUPPLIER_RETURN_REJECTED,
  globalOrderStatus.DELIVERED,
  globalOrderStatus.ACCEPTED,
  globalOrderStatus.REJECTED,
];

export const startOrcaStates = [
  globalOrderStatus.NEW,
  globalOrderStatus.RETURN_REQUESTED,
];

type Props = {
  orderData: any;
  orderHandlers: OrderHandlers;
  orderCancellationReason: string[];
  refetchAllOrders: () => void;
};

const calculateTotalRemarksLength = (remarksArray: OrderRemark[]): number => {
  let totalLength = 0;
  remarksArray.forEach((item) => {
    totalLength += item?.remarks?.length;
  });
  return totalLength;
};

const GaraazOrderComponent: React.FC<Props> = (props: Props) => {
  const { orderData, orderHandlers, refetchAllOrders } = props;
  const { data: allSuppliers } = useGetAllSuppliers();
  const { data: allBrands } = useGetBrandsInfo();

  const [isCustomerOrderFreezed, setIsCustomerOrderFreezed] = useState(false);
  const [etaMissed, setEtaMissed] = useState(false);
  const [orderStatus, setOrderStatus] = useState(orderData?.orderStatus ?? "");
  const [transactionType, setTransactionType] = useState(
    orderData?.transactionType
  );
  const [displayPartConversion, setDisplayPartConversion] = useState("flex");
  const [isEstimateRequired, setIsEstimateRequired] = useState(
    orderData?.requireEstimate
  );

  const [garaazRemarks, setGaraazRemarks] = useState(orderData.garaazRemarks);

  const [isBodyVisible, setIsBodyVisible] = useState(
    orderData.orderType === "SUBSCRIPTION" ||
      startOrcaStates.includes(orderStatus) ||
      orderStatus === globalOrderStatus.READY_TO_DISPATCH ||
      orderStatus === globalOrderStatus.DELIVERED
      ? false
      : true
  );

  const activesupplierOrderStatus: AllocatedTo = orderData?.allocatedTo?.find(
    (item: AllocatedTo) => item.isActive
  );

  useEffect(() => {
    setOrderStatus(orderData?.orderStatus || "");
    setTransactionType(orderData?.transactionType);
    setEtaMissed(false);
    if (
      orderData.customer.isBlocked === true &&
      orderData.orderDate >= orderData.customer.blockTime
    )
      setIsCustomerOrderFreezed(true);
  }, [orderData.orderMedia, orderData, orderHandlers]);

  useEffect(() => {
    if (
      startOrcaStates.includes(orderStatus) ||
      orderStatus === globalOrderStatus.READY_TO_DISPATCH ||
      orderStatus === globalOrderStatus.DELIVERED ||
      orderData.orderType === "SUBSCRIPTION"
    ) {
      setIsBodyVisible(false);
    } else {
      setIsBodyVisible(true);
    }
  }, [orderStatus]);

  const getSupplierBusinessName = useCallback(
    (supplierId: string) => {
      const supplier = allSuppliers?.find(
        (supplier: any) => supplier._id === supplierId
      );
      return supplier ? supplier.businessName : "";
    },
    [allSuppliers, orderData]
  );

  const getSupplierParentId = useCallback(
    (supplierId: string) => {
      const supplier = allSuppliers?.find(
        (supplier: any) => supplier._id === supplierId
      );
      return supplier ? supplier.parentSupplierId : "";
    },
    [allSuppliers, orderData]
  );

  const getSupplierBranchName = useCallback(
    (supplierId: any, branchId: any) => {
      const supplier = allSuppliers?.find(
        (supplier: any) => supplier._id === supplierId
      );
      const branch = supplier?.branches?.find(
        (branch: any) => branch._id === branchId
      );
      return branch ? branch.branchName : "";
    },
    [allSuppliers, orderData]
  );

  const getSupplierNumber = useCallback(
    (supplierId: string, branchId?: string | undefined) => {
      const supplier = allSuppliers?.find(
        (supplier: { _id: string }) => supplier._id === supplierId
      );
      const branch = supplier?.branches?.find(
        (branch: { _id: string }) => branch._id === branchId
      );
      return branch
        ? branch.branchPrimaryMobileNumber
        : supplier
        ? supplier.user?.mobileNumber
        : "";
    },
    [allSuppliers, orderData]
  );

  const findBrandName = useCallback(
    (brandId: string) => {
      const brand = allBrands?.find((brand: any) => brand._id === brandId);

      return brand ? brand.name.split(" ")[0] : "";
    },
    [allBrands, orderData]
  );

  function handlePartConversionChangeScreen() {
    displayPartConversion === "flex"
      ? setDisplayPartConversion("none")
      : setDisplayPartConversion("flex");
  }

  function expandBody() {
    if (isBodyVisible) setIsBodyVisible(false);
    else setIsBodyVisible(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack
        id={orderData._id}
        width={"65vw"}
        height={isBodyVisible ? "63vh" : "20vh"}
        border={"1px solid lightgrey"}
        borderRadius={"8px"}
        marginTop={"40px !important"}
        display={"flex"}
        flexDirection={"column"}
        className="order"
        gap={0}
      >
        <Tooltip
          label={removeUnderScoreWithUpperCase(orderStatus)}
          placement="left-start"
          hasArrow
          cursor={"pointer"}
          color={"white"}
          bg={getGaraazStateColor(orderStatus)}
          position={"relative"}
        >
          <div
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              background: `linear-gradient(white, white) padding-box, ${getGaraazStateColor(
                orderStatus
              )} border-box`,
              borderTop: "6px solid transparent",
              height: "6px",
              bottom: "0px",
              top: "0px",
              paddingBottom: "0px",
              paddingTop: "0px",
              cursor: "pointer",
              padding: "0px !important",
            }}
          />
        </Tooltip>

        <GaraazOrderComponentHeader
          expandBody={expandBody}
          isBodyVisible={isBodyVisible}
          activesupplierOrderStatus={activesupplierOrderStatus}
          etaMissed={etaMissed}
          setEtaMissed={setEtaMissed}
          findBrandName={findBrandName}
          getSupplierBusinessName={getSupplierBusinessName}
          getSupplierParentId={getSupplierParentId}
          orderData={orderData}
          orderStatus={orderStatus}
          transactionType={transactionType}
          isCustomerOrderFreezed={isCustomerOrderFreezed}
          getSupplierBranchName={getSupplierBranchName}
          getSupplierNumber={getSupplierNumber}
          isEstimateRequired={isEstimateRequired}
          isUrgent={!!orderData?.isUrgent}
          partType={orderData?.partType}
        ></GaraazOrderComponentHeader>
        {!startOrcaStates.includes(orderStatus) && isBodyVisible && (
          <GaraazOrderComponentBody
            activesupplierOrderStatus={activesupplierOrderStatus}
            displayPartConversion={displayPartConversion}
            findBrandName={findBrandName}
            getSupplierBusinessName={getSupplierBusinessName}
            orderData={orderData}
            orderStatus={orderStatus}
            garaazRemarks={garaazRemarks}
          ></GaraazOrderComponentBody>
        )}

        {orderData.orderType === "REGULAR" ? (
          <GaraazOrderComponentFooter
            setOrderStatus={setOrderStatus}
            activesupplierOrderStatus={activesupplierOrderStatus}
            handlePartConversionChangeScreen={handlePartConversionChangeScreen}
            orderCancellationReason={props.orderCancellationReason}
            orderData={orderData}
            orderStatus={orderStatus}
            refetchAllOrders={refetchAllOrders}
            setEtaMissed={setEtaMissed}
            isCustomerOrderFreezed={isCustomerOrderFreezed}
            garaazRemarks={garaazRemarks}
            setGaraazRemarks={setGaraazRemarks}
            setTransactionType={setTransactionType}
          ></GaraazOrderComponentFooter>
        ) : orderData.orderType === "RETURN" ? (
          <GaraazOrderComponentFooterReturn
            setOrderStatus={setOrderStatus}
            activesupplierOrderStatus={activesupplierOrderStatus}
            handlePartConversionChangeScreen={handlePartConversionChangeScreen}
            orderCancellationReason={props.orderCancellationReason}
            orderData={orderData}
            orderStatus={orderStatus}
            refetchAllOrders={refetchAllOrders}
            setEtaMissed={setEtaMissed}
            isCustomerOrderFreezed={isCustomerOrderFreezed}
            garaazRemarks={garaazRemarks}
            setGaraazRemarks={setGaraazRemarks}
          ></GaraazOrderComponentFooterReturn>
        ) : (
          <GaraazOrderComponentFooterSubscription
            handlePartConversionChangeScreen={handlePartConversionChangeScreen}
            orderCancellationReason={props.orderCancellationReason}
            orderData={orderData}
            orderStatus={orderStatus}
            refetchAllOrders={refetchAllOrders}
            setEtaMissed={setEtaMissed}
            setOrderStatus={setOrderStatus}
            isCustomerOrderFreezed={isCustomerOrderFreezed}
          ></GaraazOrderComponentFooterSubscription>
        )}
      </Stack>
    </ThemeProvider>
  );
};

export default GaraazOrderComponent;
